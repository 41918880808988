<script setup>
// Required Component
import { computed } from 'vue'
// Store
import store from '@/store'

const mobileMenu = computed(() => {
  return store.getters.menu
})

// Toggle Mobile Menu
const toggleMobileMenu = () => {
  store.dispatch('ToggleMenu')
}
</script>

<template>
  <nav class="top-ebBuilder-nav"
       @click.prevent="toggleMobileMenu">
    <h5 class="top-ebBuilder-nav-text">
      {{$t('eventBuilder.displayBuilderMobile')}}
    </h5>
    <i class="fa-solid text-blacks-300 ml-2"
       :class="{
          'fa-chevron-down' : !mobileMenu,
          'fa-chevron-up' : mobileMenu
        }" />
  </nav>
</template>

<style lang="scss" scoped>
.top-ebBuilder-nav {
  @apply w-full py-3 flex flex-row justify-center items-center fixed top-[3.8rem] md:hidden bg-background-500 -ml-4 z-[2];
  @apply cursor-pointer;
  &-text {
    @apply text-h5 font-medium text-blacks-500;
  }
}
</style>
