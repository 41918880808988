<script setup>
// Required Packages
import { computed, onMounted, ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
// Store
import store from '@/store'
// Component Library
import Overflow from '@/components/Base/Menu/Overflow'
import BaseButton from '@/components/Base/Button/BaseButton'
import CardSlot from '@/components/Base/Cards/CardSlot'
// Component Page
import EventPageBase from '@/pages/EventBuilder/EventPageBase'
// Helper
import { processStatuses, bitwiseOperators, eventStatuses } from '@/utils'

const router = useRouter()
const route = useRoute()
const isDraft = ref(false)

const projectMenu = ref(false)

const event = computed(() => {
  return store.getters.eventBuilderEvent
})

const eventTemplate = computed(() => {
  return store.getters.getEventTemplate
})

const masterTemplates = computed(() => {
  return store.getters.masterTemplates // templates are within template
})

const showTemplateSelection = computed(() => {
  return store.getters.isEBEventTemplateLoaded && !store.getters.hasEventTemplate
})

const existingTemplate = computed(() => {
  return store.getters.template
})

const selectTemplate = (id) => {
  store.dispatch('setSelectedMasterTemplateID', id)
  router.push({ name: 'Template', params: { slug: route.params.slug, id: id }})
}

const selectProject = (id) => {
  router.push({ name: 'Project', params: { slug: route.params.slug, id: id }})
}

const deleteProject = async (id) => {
  const payload = {
    eventId: id,
    title: 'Delete Template',
    description: 'Are you sure you want to delete this template?',
    confirmButton: 'Delete',
    cancelButton: 'Cancel',
    callFrom: 'deleteTemplate',
    confirmAction: () => {
      store.dispatch('deleteProject', id)
      store.dispatch('deleteEventTemplate', store.getters.getEventTemplate.id).then(() => {
        store.dispatch('updateEventConfigs', { eventId: store.getters.ebEventId, configKey: "event_config_completion", configValue: "8", isConfigValueBitMask: true, bitwiseOperation: bitwiseOperators.REMOVE }).then(() => {
          // Pull the event again to update event's event template
          store.dispatch('getEvent', event.value.id)
          store.dispatch('getMasterTemplates')
          store.dispatch('getEventConfigs', { eventId: store.getters.ebEventId})
        }).catch((err) => {
          console.error(err)
        })
      })
      store.dispatch('GetEventBuilderItems')
      //this is to go back to the template selection once current template is deleted
      store.dispatch('ResetModal')
    },
    cancelAction: () => {
      store.dispatch('ResetModal')
    },
    type: 'confirmTemplate'
  }
  //this is to dispatch delete confirmation modal
  await store.dispatch('ToggleModalTemplateConfirmDelete', payload)
}

const updateEventTemplate = async (eventTemplateId, status) => {
  await store.dispatch('updateEventTemplate', { eventTemplateId, status}).then((res) => {
    if (res.status === "Draft") {
      isDraft.value = true
      store.dispatch('updateEventConfigs', { eventId: store.getters.ebEventId, configKey: "event_config_completion", configValue: "8", isConfigValueBitMask: true, bitwiseOperation: bitwiseOperators.REMOVE }).then(() => {
        store.dispatch('getEventConfigs', { eventId: store.getters.ebEventId})
        store.dispatch('GetEventBuilderItems')
      }).catch((err) => {
        console.error(err)
      })
    } else {
      isDraft.value = false
    }
  })
  await store.dispatch('getEvent', event.value.id)
  toggleProjectMenu()
}

const toggleProjectMenu = () => {
  projectMenu.value = !projectMenu.value
}

const eventTemplateId = computed(() => {
  return store.getters.getEventTemplateId
})

watch(() => showTemplateSelection.value, (value) => {
  if (value) {
    store.dispatch('getMasterTemplates')
  } else if (eventTemplateId.value) {
    store.dispatch('getTemplate', eventTemplateId.value)
  }
})

watch(() => eventTemplateId.value, (value) => {
  if (value) {
    store.dispatch('getTemplate', value)
  }
})

onMounted(() => {
  if (showTemplateSelection.value) {
    store.dispatch('getMasterTemplates')
  } else if (eventTemplateId.value) {
    store.dispatch('getTemplate', eventTemplateId.value)
  }
})


const getEBEventStatus = computed(() => {
  return store.getters.ebEventStatus
})

const getTemplateLoadingStatus = computed(() => {
  return store.getters.masterTemplatesLoading
})

const isEventPublished = computed(() => {
  return event.value.eventSettings?.status === eventStatuses.LIVE
})

const next = () => {
  router.push({ name: 'Timeline', params: { slug: event.value.slug }})
  window.scrollTo(0, 0)
}

const prev = () => {
  router.push({ name: 'Branding', params: { slug: event.value.slug }})
  window.scrollTo(0, 0)
}

const success = () => {
  store.dispatch('triggerToast',{ message: 'Your resources has been successfully saved.', type: 'success'}).then(() => {
    if (route.params.slug !== event.value.slug) {
      router.push({ name:'Resources', params: { slug: event.value.slug } })
    }
  })
}
</script>

<template>
  <event-page-base header-class="project">
    <template #title>
      Project Template
    </template>
    <template #description>
      <div v-if="showTemplateSelection"
           class="project-description">
        Templates are guides to help participants upload their work during the
        <router-link to="timeline" class="project-description_link">Project Submission Period</router-link>.
        Choose a template that's right for you.
      </div>
    </template>
    <template #content>
      <div v-if="getEBEventStatus === processStatuses.LOADING">LOADING</div>
      <!-- Loads Project(s) -->
      <ul v-else-if="!showTemplateSelection"
          class="project-card-list">
        <li class="project-card-list-item">
          <card-slot class-name="project-card-item">
            <template #header>
              <!-- Project Card Header -->
              <span class="project-card-header">
                <span class="project-card-header-status"
                      :class="{
                          'status-green': eventTemplate?.status === 'Published',
                          'status-black': eventTemplate?.status === 'Draft'
                        }">
                  <i class="fa-solid fa-period fa-xl mr-2"/>
                  <span class="card-header_text">
                    {{ eventTemplate?.status }}
                  </span>
                </span>
                <overflow :menu-items="[]" :disabled="isEventPublished" position="left">
                  <template #menu-items>
                    <ul class="project-card-menu-list">
                  <li class="project-card-menu-item"
                      @click.prevent="selectProject(existingTemplate.id)">
                    <i class="fa-thin fa-pencil mr-2" />Edit Template
                  </li>
                  <li class="project-card-menu-item"
                      @click.prevent="() => deleteProject(existingTemplate.id)">
                    <i class="fa-thin fa-trash-can mr-2" />Delete Template
                  </li>
                  <li v-if="eventTemplate?.status === 'Published'" class="project-card-menu-item"
                      @click.prevent="() => updateEventTemplate(eventTemplate.id, 'Draft')">
                    <i class="fa-thin fa-pencil mr-2" />Back To Draft
                  </li>
                </ul>
                  </template>
                </overflow>
              </span>
            </template>
            <template #title>
              <div class="project-card-item_title">
                <h1 class="font-bold line-clamp-3">
                  {{ existingTemplate.title }}
                </h1>
              </div>
            </template>
            <template #content>
              <div class="project-card-item_image"
                   :style="{'background-image': `url(${existingTemplate.imageUrl})`}"
                   @click.prevent="selectProject(existingTemplate.id)"/>
            </template>
          </card-slot>
        </li>
      </ul>
      <!-- Loads the template selection  -->
      <div v-else-if="showTemplateSelection"
           class="project-card">
        <div class="project-card-nav">
          <h1 class="project-card_title">template library</h1>
          <base-button id="moreTemplates"
                       name="moreTemplates"
                       btn-type="tertiary"
                       aria-label="more templates"
                       :disabled="true"
                       class-name="project-card_btn">
            <template #contents>
              More templates
              <i class="fa-solid fa-lock ml-4" />
            </template>
          </base-button>
        </div>
        <ul class="project-card-list" :class="{'skeleton': getTemplateLoadingStatus}">
          <li v-for="template in masterTemplates"
              :key="template.id"
              class="w-full md:w-auto relative"
              @click.prevent="selectTemplate(template.id)">

              <card-slot class-name="project-card-item">
                <template #title>
                  <div class="project-card-item_title">
                    <h1 v-if="!getTemplateLoadingStatus" class="font-bold line-clamp-3">
                      {{ template.title }}
                    </h1>
                    <h1 v-else></h1>
                  </div>
                </template>
                <template #content>
                  <div v-if="!getTemplateLoadingStatus" class="project-card-item_image"
                       :style="{'background-image': `url(${template.imageUrl})`}"/>
                  <div v-else class="project-card-item_image"/>
                </template>
              </card-slot>
          </li>
        </ul>
      </div>
    </template>
    <template #bottom-nav-container>
      <base-button
          id="bottom-nav-prev"
          name="bottom-nav-prev"
          class-name="bottom-nav-button"
          btn-type="text"
          :text="$t('eventBottomNav.back')"
          :functions="prev">
        <template #contents>
          <span class="bottom-nav-button-text">
            <span class="bottom-nav-circle">
              <i class="fa-solid fa-chevron-left" />
            </span>
            {{ $t('eventBottomNav.back') }}
          </span>
        </template>
      </base-button>
      <base-button
          id="bottom-nav-next"
          name="bottom-nav-next"
          class-name="bottom-nav-button"
          btn-type="text"
          :functions="next"
          :text="$t('eventBottomNav.next')">
        <template #contents>
          <span class="bottom-nav-button-text">
            <span class="bottom-nav-circle">
              <i class="fa-solid fa-chevron-right" />
            </span>
            {{ $t('eventBottomNav.next') }}
          </span>
        </template>
      </base-button>
      <base-button :text="$t('eventBottomNav.updateBtn')"
                   :disabled="!isDraft"
                   class-name="project-button"
                   aria-label="Update Button"
                   :functions="success" />
    </template>
  </event-page-base>
</template>

<style lang="scss" scoped>
.project {
  @apply w-full flex flex-col;
  &-description {
    @apply w-full md:max-w-[35rem];
    &_link {
      @apply text-purple capitalize underline font-semibold;
    }
  }
  &-card {
    @apply w-full bg-white p-6 rounded-lg my-8 md:px-8 md:pt-10 pb-24 max-w-[55rem];
    &-nav {
      @apply w-full flex flex-row justify-between;
    }
    &-header {
      @apply px-4 pt-2 text-xs absolute top-0 left-0 w-full flex flex-row justify-between;
      &_title {
        @apply truncate max-w-[12rem];
      }
      &-status {
        @apply flex flex-row;
      }
    }
    &_title {
      @apply capitalize font-semibold;
    }
    &_btn {
      @apply uppercase text-white text-sm rounded px-4 py-2;
    }
    &-list {
      @apply rounded-lg flex flex-wrap grow gap-4 py-6;
      &-item {
        @apply relative md:w-auto w-full;
      }
    }
    &-menu { // Project Card Header Menu
      &-list {
        @apply text-p-md md:text-p-xs flex flex-col items-start;
      }
      &-item {
        @apply py-3 ml-6 hover:text-product-400;
      }
    }
    &-item { // Project Card Item
      @apply w-full md:w-[16rem] h-[16rem] bg-product-400 rounded-lg drop-shadow-2xl cursor-pointer;
      &_title {
        @apply h-[7rem] bg-white font-bold rounded-tl-lg rounded-tr-lg px-4 pt-[1.5rem] flex items-center;
      }
      &_image {
        @apply bg-cover h-[9rem] bg-no-repeat bg-center rounded-b-lg;
      }
    }
  }
  &-button {
    @apply capitalize px-6 py-2 mt-1;
  }
}

.skeleton {
  .project {
    &-card {
      &-item {
        &_image {
          @apply h-[9rem] w-full rounded-b-lg cursor-pointer flex justify-end items-end animate-[pulse-bg_2s_infinite] bg-background-500 bg-no-repeat bg-gradient-to-r from-background-500 via-blacks-100 to-background-500;
        }
      }
    }
  }
  h1 {
    @apply animate-[pulse-bg_2s_infinite] w-full h-[1.5rem] bg-background-500 bg-no-repeat bg-gradient-to-r from-background-500 via-blacks-100 to-background-500;
  }
}
@keyframes pulse-bg {
  0% {
    @apply [background-position-x:-29rem];
  }

  100% {
    @apply [background-position-x:29rem];
  }
}
</style>
