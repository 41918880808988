<script setup>
// Packages
import { computed, ref, watch, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
// Custom packages
import store from '@/store'
// Base components
import BaseButton from '@/components/Base/Button/BaseButton'
// Custom components
import EventPageBase from './EventPageBase'
import ResourceCard from '@/components/Resources/ResourceCard'
// Utils
import { processStatuses } from "@/utils"

const router = new useRouter()
const route = new useRoute()

const event = computed(() => {
  return store.getters.eventBuilderEvent
})

const comingSoon = ref(false)
const fetchResources = () => {
  if (event.value.id > 0) {
    store.dispatch('fetchResources', { eventId: event.value.id })
  }
}

const isResourcesStatusLoading = computed(() => {
  return store.getters.getStatus === processStatuses.LOADING
})


const resources = computed(() => {
  return store.getters.getResources.length > 0 ? store.getters.getResources : (store.getters.getStatus === processStatuses.LOADING ? [{}, {}] : [])
})

const resourceModal = () => {
  store.dispatch('resetResourceModal')
  store.dispatch('ToggleModalResource')
}

const prev = () => {
  router.push({ name: 'Timeline', params: { slug: event.value.slug }})
  window.scrollTo(0, 0)
}

const next = () => {
  router.push({ name: 'Categories', params: { slug: event.value.slug }})
  window.scrollTo(0, 0)
}

const success = () => {
  store.dispatch('triggerToast',{ message: 'Your resources has been successfully saved.', type: 'success'}).then(() => {
    if (route.params.slug !== event.value.slug) {
      router.push({ name:'Resources', params: { slug: event.value.slug } })
    }
  })
}

watch(() => store.getters.eventBuilderEvent, () => {
  fetchResources()
})

onMounted(() => {
  fetchResources()
})
</script>

<template>
  <event-page-base :coming-soon="comingSoon">
    <template #title>
      Resources
    </template>
    <template v-if="!comingSoon" #description>
      Resources help give your participants additional information they may need for you event.
    </template>
    <template v-if="comingSoon" #coming-soon>
      <div class="text-product-400 text-sm py-1"><i class="fa fa-star" /> Resource download coming soon</div>
    </template>
    <template #content>
      <div class="resources">
        <ul class="resources-list" :class="{'skeleton': isResourcesStatusLoading}">
          <resource-card
            v-for="(resource, index) in resources"
            :key="index"
            :resource-index="index"
            :is-loading="isResourcesStatusLoading"
            :resource="resource"
            :is-last="(resources.length == (index+1))"/>
            <li v-if="!isResourcesStatusLoading" class="card card-me skeleton-card" @click="resourceModal">
              <span class="card-container">
                <i class="fa-thin fa-plus card-icon" />
                <p>Add Resource</p>
              </span>
            </li>
        </ul>
      </div>
    </template>
    <template #bottom-nav-container>
      <base-button
          id="bottom-nav-prev"
          name="bottom-nav-prev"
          class-name="bottom-nav-button"
          btn-type="text"
          :text="$t('eventBottomNav.back')"
          :functions="prev">
        <template #contents>
          <span class="bottom-nav-button-text">
            <span class="bottom-nav-circle">
              <i class="fa-solid fa-chevron-left" />
            </span>
            {{ $t('eventBottomNav.back') }}
          </span>
        </template>
      </base-button>
      <base-button
          id="bottom-nav-next"
          name="bottom-nav-next"
          class-name="bottom-nav-button"
          btn-type="text"
          :functions="next"
          :text="$t('eventBottomNav.next')">
        <template #contents>
          <span class="bottom-nav-button-text">
            <span class="bottom-nav-circle">
              <i class="fa-solid fa-chevron-right" />
            </span>
            {{ $t('eventBottomNav.next') }}
          </span>
        </template>
      </base-button>
      <base-button :text="$t('eventBottomNav.updateBtn')"
                   class-name="resources-button"
                   aria-label="Update Button"
                   :functions="success" />
    </template>
  </event-page-base>
</template>

<style lang="scss" scoped>
.resources {
  @apply mt-6 max-w-[100rem] pb-[12rem];
  &-list {
    @apply flex flex-wrap gap-4 justify-center items-center md:justify-start md:items-start;
  }
  &_dropdown {
     @apply md:text-left text-center mb-5;
    &-btn {
      @apply text-purple-dark border border-purple-dark px-3 py-1.5 text-sm font-normal inline-flex items-center bg-none;
    }
  }
  &-button {
    @apply capitalize px-6 py-2 mt-1;
  }
}
.card {
  &-me {
    @apply flex justify-center items-center text-center border-2 rounded-xl border-dashed cursor-pointer p-5 w-[21.875rem] h-[17.063rem];
  }
  &-icon {
    @apply text-4xl rounded-full w-[4.375rem] h-[4.375rem] leading-[4.375rem] font-bold mb-3 text-product-400 bg-product-100;
  }
}
</style>



