<script setup>
// Required Packages
import { computed, onMounted, reactive, watch, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
// Store
import store from '@/store'
// Component Libs
import BaseInput from '@/components/Base/Fields/InputField'
import LoginButton from '@/components/Base/Button/BaseButton'
// Custom components
import OverflowCustom from '@/components/Layout/Navbars/Top/OverflowCustom'
import { config } from '@/config'
// Router

// Props
defineProps({
  sidebarBtn: {
    type: Boolean,
    default: false
  }
})

const { t } = useI18n()

// Temporary for user data
const user = reactive({
  search: ''
})
// Temporary disable
const isMvp = true
const isEventBuilder = ref(true)
// Set up the route
const route = useRoute()
// Menu states
const menu = reactive({
  notification: false,
  profile: false,
  profileMenu: []
})
// Error Page
const isErrorPage = computed(() => {
  return route.name === 'NotFound' || route.name === 'NotAccess'
})
// Get mobile menu state
const mobileMenu = computed(() => {
  return store.getters.menu
})
// Get user info
const userProfile = computed(() => {
  return store.getters.user
})
// User is logged in
const isLoggedIn = computed(() => {
  return store.getters.isLoggedIn || false
})
// Notifications info
const notifications = computed(() => {
  return store.getters.notifications || []
})
// Login to dispatch modal
const login = () => {
  store.dispatch('ToggleModalLogin')
}

// Toggle Mobile Menu
const toggleMobileMenu = () => {
  store.dispatch('ToggleMenu')
}
// Get profile menu either by store or local constant
// TO DO -> GET USER PROFILE AND UPDATE THE USER STORE
// CREATE THE STYLE FOR MOBILE SIDEBAR
const getProfileItems = () => {
  menu.profileMenu = [
    // Re-enabled after Project Progress here
    // {
    //   name: 'My Events',
    //   icon: 'fa-light fa-calendar',
    //   component: 'Events'
    // },
    // {
    //   name: 'My Projects',
    //   icon: 'fa-light fa-book',
    //   component: 'Projects'
    // },
    // {
    //   name: 'My Bookmark',
    //   icon: 'fa-light fa-bookmark',
    //   component: 'Bookmark'
    // },
    {
      name: t('dropdownMenu.accountSettings'),
      icon: 'fa-light fa-gear',
      component: 'Home',
      action: async () => {
        window.location.href = `${config.PORTAL_URL}/settings`
      }
    },
    {
      name: t('dropdownMenu.logout'),
      icon: 'fa-light fa-arrow-right-from-bracket',
      component: 'Home',
      action: async () => {
        await store.dispatch('logout')
      }
    }
  ]
}

const isPreview = computed(() => {
  return route.name === 'Preview'
})

watch(() => route.path, (value) => {
  isEventBuilder.value = !!value?.includes('/event-builder')
})

onMounted(() => {
  getProfileItems()
})
</script>

<template>
  <nav class="nav">
    <div class="nav-container"
         :class="{
          'md:pl-[19rem] ' : !sidebarBtn && !isErrorPage && !isPreview,
          'md:pl-0' : isPreview,
          'md:pl-[7rem]' : sidebarBtn
          }">
      <slot name="logo">
        <span class="nav-container-left">
          <span v-if="!isEventBuilder"
                class="nav-container-left-icon">
            <i class="fa-solid"
               :class="{'fa-bars' : !mobileMenu, 'fa-xmark' : mobileMenu}"
               @click.prevent="toggleMobileMenu"/>
          </span>
          <router-link :to="{name: 'Events' }"
                       class="nav-logo">
            <img src="/images/logo/projectboard.png"
                 alt="preview logo"
                 class="nav-logo-img">
          </router-link>
        </span>
      </slot>
      <slot name="menu">
        <span class="nav-menu"
              :class="{
                'md:w-[23rem] w-[7rem]': isLoggedIn,
                'md:w-[21rem] w-[7rem]': !isLoggedIn
              }">
          <span class="hidden md:inline-block">
            <base-input label-text="Search"
                         input-name="Search"
                         input-id="Search"
                         class-name="input-search"
                         input-aria-label="Search button"
                         input-place-holder="Search"
                         :disabled="true"
                         :model-value="user.search"
                         :input-model="user.search" />
          </span>
          <!-- Notifications -->
           <overflow-custom menu-type="notifications"
                            :disabled="isMvp"
                            :menu-toggle="menu.notification"
                            :menu-items="notifications" />
          <!-- Login Button -->
          <login-button v-if="!isLoggedIn"
                        id="login"
                        name="login"
                        :functions="login"
                        text="Login" />
          <!-- Profile Menu -->
          <overflow-custom id="profile"
                           menu-type="profile"
                           :menu-toggle="menu.profile"
                           :menu-items="menu.profileMenu"
                           :user="userProfile"/>
        </span>
      </slot>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.field {
  @apply p-2;
}
.nav {
  @apply fixed top-0 w-full bg-background-400 px-6 border-b border-greys-100 z-[3] md:py-0 py-3;
  &-container {
    @apply flex flex-row w-full justify-between;
    &-left {
      @apply flex flex-row items-center justify-center;
      &-icon {
        @apply md:hidden flex justify-center items-center mr-[20px] text-[1.2rem] cursor-pointer w-5 h-5;
      }
    }
  }
  &-logo {
    @apply flex items-center justify-center;
    &-img {
      @apply w-auto h-5;
    }
  }
  &-menu {
    @apply flex flex-row gap-4 items-center relative justify-end;
    &-avatar {
      @apply w-full h-full rounded-full text-sm ring-transparent bg-product-400/[0.1];
      &:hover {
        @apply ring ring-1 ring-secondary;
      }
      &-container {
        @apply w-full flex items-center justify-center;
      }
      &_text {
        @apply font-medium text-product-400 uppercase;
      }
    }
    // Notifications
    &-notifications {
      @apply p-6 rounded-xl w-[22rem];
      &-container {
        @apply flex flex-row justify-between w-full;
      }
    }
    // Profile Styles
    &-profile {
      @apply p-6 rounded-lg w-[16rem];
      &-container {
        @apply flex flex-row;
      }
      &-avatar {
        @apply w-10 h-10 rounded-full;
      }
      &-textContainer {
        @apply flex flex-col ml-4;
      }
      &-name {
        @apply text-h5 font-medium;
      }
      &-viewText {
        @apply text-p-xs font-medium text-product-400;
      }
      &-items {
        @apply flex flex-row items-center py-4 text-p-sm hover:text-product-400 cursor-pointer;
      }
      &-separator {
        @apply w-full h-0.5 mt-4;
      }
    }
  }
}

.input {
  &-search {
    @apply max-w-[10rem];
  }
}
</style>
