<script setup>
// Routing
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import store from '@/store'

import { pbCircleLoader } from 'pb-components'

const props = defineProps({
  sideBarItems: {
    type: Array,
    required: true
  },
  isMobileToggle: {
    type: Boolean,
    default: false
  },
  isMobileMenu: {
    type: Boolean,
    default: false
  }
})

const route = useRoute()

const isMenuLoading = computed(() => {
  return props.sideBarItems.length === 0
})

const isCurrentRoute = (path) => {
  return route.name === path
}

const closeMobileMenu = () => {
  store.dispatch('ToggleCloseMenu')
}
</script>

<template>
  <div v-if="isMenuLoading"
       class="menu-loader">
    <pb-circle-loader />
  </div>
  <ul v-else
      class="menu">
    <router-link
        v-for="menuItem in sideBarItems"
        :key="menuItem.title"
        :active-class="`menu-item_active ${isMobileToggle ? '' : 'menu-item_active-desktop'}`"
        class="menu-item menu-item_notActive"
        :to="{name: menuItem.name, query: { lang: route.query.lang }}"
        @click.prevent="closeMobileMenu">
      <span class="menu-icon"
            :class="{
              'px-3.5': isMobileToggle,
              'px-7': !isMobileToggle
            }">
        <i :class="`fa-${
           isCurrentRoute(menuItem.name) ? 'regular' : 'light'
        } fa-${menuItem.icon} ${isMobileMenu ? '' : 'fa-sm'}`" />
      </span>
      <span :class="{'hidden' : isMobileToggle }"
            class="menu-item-size">
        {{ menuItem.title }}
      </span>
    </router-link>
  </ul>
</template>

<style lang="scss" scoped>
.menu {
  @apply py-1;
  &-loader {
    @apply w-full h-full;
  }
  &-item {
    @apply py-2.5 font-light cursor-pointer flex flex-row items-center rounded-lg;
    &:hover {
      @apply font-medium;
    }
    &_notActive {
      @apply border-l-8 border-transparent;
      &:hover {
        @apply border-purple border-l-8 bg-product-400/[0.1];
      }
    }
    &_active {
      @apply bg-product-400/[0.1] cursor-default font-medium;
      &-desktop {
        @apply border-purple border-l-8;
      }
    }
    &-size {
      @apply text-p-md capitalize min-w-[10rem];
    }
  }
  &-icon {
    @apply w-8 h-8 flex items-center;
  }
}
</style>
