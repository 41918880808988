import { createI18n } from 'vue-i18n'
import { messages } from '@/locale'

// Supported languages
const supportedLanguages = ['en', 'ar']

// Detect the browser language
const browserLanguage = navigator.language || navigator.userLanguage
const language = browserLanguage.split('-')[0]

// Get the "lang" query parameter from the URL
const urlParams = new URLSearchParams(window.location.search)
const langQuery = urlParams.get('lang')

const loadLanguage = (lang) => {
  if (langQuery && supportedLanguages.includes(langQuery)) return lang
  return language
}

export default createI18n({
  legacy: false,
  locale: loadLanguage(langQuery),
  fallbackLocale: 'en',
  messages: messages
})
