<script setup>
// Required Components
import { computed, reactive, onMounted, watch, onUnmounted } from 'vue'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
// Store
import store from '@/store'
// Base Components
import BaseButton from '@/components/Base/Button/BaseButton'
import CreateTable from '@/components/Awards/CreateTable'
// Custom Components
import EventPageBase from '@/pages/EventBuilder/EventPageBase'
import CardEventBuilder from '@/components/Base/Cards/EventBuilder'
import { processStatuses } from '@/utils'
import { eventFeatureCodes } from '@/utils/eventFeatures';
import { v4 as uuidv4 } from 'uuid'

// Create Awards Content
const createAwards = reactive({
  title: "You Haven't Created Any Awards Yet",
  description: "You can choose from a library or upload your own. Get started below."
})

const router = useRouter()

const event = computed(() => {
  return store.getters.eventBuilderEvent
})

const eventStatus = computed(() => {
  return store.getters.ebEventStatus
})

const isAwardsPublished = computed(() => {
  return store.getters.getAwardsPublished
})


/*
const isEventPublished = computed(() => {
  return event.value.eventSettings?.status === eventStatuses.LIVE
})*/

const awardFeatureEnabled = computed(() => {
  const feature = store.getters.getEventFeature(eventFeatureCodes.ENABLE_AWARDS)
  return feature?.value === 1
})

const awardList = computed(() => {
  return store.getters.awards
})

const awardListStatus = computed(() => {
  return store.getters.awardsStatus
})

const isSectionReady = computed(() => {
  if (store.getters.ebEventStatus !== processStatuses.LOADED) {
    return false
  } else {
    if (awardFeatureEnabled.value) {
      return store.getters.awardsStatus === processStatuses.LOADED
    } else {
      return true
    }
  }
})

const awardImageUploadingStatus = computed(() => {
  return store.getters.awardImageUploadStatus
})

const uploadButtonIsLoading = computed(() => {
  return [processStatuses.SAVING, processStatuses.LOADING].includes(awardListStatus.value) || awardImageUploadingStatus.value === processStatuses.SAVING
})

const uploadButtonIsDisabled = computed(() => {
  return uploadButtonIsLoading.value || !store.getters.isAwardsUpdated || eventStatus.value !== processStatuses.LOADED
})

const next = () => {
  router.push({ name: 'GiveAwards', params: { slug: event.value.slug }})
  window.scrollTo(0, 0)
}

const addAwards = () => {
  store.dispatch('addEventAward', {
    id: uuidv4(),
    name: '',
    image: ''
  })
}

onMounted(() => {
  // Fetch awards only when award feature is enabled
  if (awardFeatureEnabled.value) {
    store.dispatch('fetchEventAwards')
  }
  addEventListener("beforeunload", beforeUnloadHandler)
})

watch(() => store.getters.ebEventStatus, (status) => {
  if (status === processStatuses.LOADED && awardListStatus.value === processStatuses.IDLE && awardFeatureEnabled.value) {
    store.dispatch('fetchEventAwards')
  }
})

const saveAwards = () => {
  // Validate awards
  const hasEmptyFields = awardList.value.some(obj => !obj.name || !obj.image);
  const sortedArray = awardList.value.slice().sort((a, b) => a.name.localeCompare(b.name));
  const hasDuplicates = sortedArray.some((obj, index) => {
    return index > 0 && obj.name === sortedArray[index - 1].name;
  })

  // Empty image and name
  if (hasEmptyFields) {
    store.dispatch('setErrorList', hasEmptyFields)
    store.dispatch('triggerToast', { message: 'Awards must have both name and icon specified.', type: 'error' })
    return
  }
  // Duplicate names
  if (hasDuplicates) {
    store.dispatch('setErrorList', hasDuplicates)
    store.dispatch('triggerToast', { message: 'Please make sure awards names are unique', type: 'error' })
    return
  }

  store.dispatch('uploadAwardImages').then(() => {
    store.dispatch('saveEventAwards').then(() => {
      store.dispatch('triggerToast',{ message: 'Awards have been updated', type: 'success'})
    }).catch(() => {
      store.dispatch('triggerToast',{ message: 'Failed to update awards', type: 'error'})
      // Fetch awards after error
      store.dispatch('fetchEventAwards')
    })
  }).catch(() => {
    store.dispatch('triggerToast',{ message: 'Failed to upload award images', type: 'error'})
  })
}

const triggerUpgradeModal = () => {
  const subscriptionDetails = store.getters.getEventSubscriptionDetails
  if (store.getters.hasRemainingPaidEvents(subscriptionDetails?.plan?.name)) {
    store.dispatch('ToggleModalUpgradeEvent', { eventId: store.getters.ebEventId })
  } else {
    store.dispatch('ToggleModalUpgradePayment', {
      eventId: store.getters.ebEventId,
      currentPlan: subscriptionDetails?.plan?.name
    })
  }
}

const beforeUnloadHandler = async (event) => {
  if (store.getters.isAwardsUpdated) {
    event.preventDefault()
    // Allow the page to be unloaded
    event.returnValue = true
  }
}

onUnmounted(() => {
  store.dispatch('resetAwardsState')
  // Remove beforeunload event listener when the component is unmounted
  removeEventListener("beforeunload", beforeUnloadHandler)
})

onBeforeRouteLeave((to, from, next) => {
  if(store.getters.isAwardsUpdated){
    const payload = {
      confirmAction: () => {
        store.dispatch('ResetModal')
        next()
      },
      cancelAction: () => {
        store.dispatch('ResetModal')
      }
    }
    store.dispatch('ToggleModalUnsavedAlert', payload)
  } else {
    next()
  }
})
</script>

<template>
  <event-page-base :is-published="isAwardsPublished">
    <template #title>
      Create Awards
    </template>
    <template #description>
      Here, you can make badges and awards for your project showcase. Once projects are approved, you can give awards to chosen participants!
    </template>
    <template #error-message>
      This section is no longer editable as the awards have already been given. Please email our
      <a class="underline" href="mailto:projectboardplus@projectboard.zohodesk.com"> support</a>
      for further questions.
    </template>
    <template #content>
      <div v-if="!isSectionReady">Loading</div>
      <template v-else>
        <card-event-builder v-if="awardList.length === 0"
                            image="https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/SelfServe%2Fawards%2Faward_management.png?alt=media&token=dedef5e9-fab5-41a9-9fe9-e051c7c5c6a6"
                            :title="createAwards.title"
                            :content="createAwards.description"
                            :is-create="true"
                            :btn-text="awardFeatureEnabled ? 'Add Awards' : 'Upgrade To Basic or Premium'"
                            :btn-disabled="awardFeatureEnabled && awardListStatus !== processStatuses.LOADED"
                            :functions="awardFeatureEnabled ? addAwards : triggerUpgradeModal"/>
        <create-table v-else />
      </template>
    </template>
    <template #bottom-nav-container>
      <base-button
          id="bottom-nav-next"
          name="bottom-nav-next"
          class-name="bottom-nav-button"
          btn-type="text"
          :functions="next"
          :text="$t('eventBottomNav.next')">
        <template #contents>
          <span class="bottom-nav-button-text">
            <span class="bottom-nav-circle">
              <i class="fa-solid fa-chevron-right" />
            </span>
            {{ $t('eventBottomNav.next') }}
          </span>
        </template>
      </base-button>
      <base-button :text="$t('eventBottomNav.updateBtn')"
                   :disabled="uploadButtonIsDisabled"
                   :is-loading="uploadButtonIsLoading"
                   aria-label="Update Button"
                   class-name="create-awards-button"
                   :functions="saveAwards" />
    </template>
  </event-page-base>
</template>

<style lang="scss" scoped>
.create-awards {
  &-button {
    @apply capitalize px-6 py-2 mt-1;
  }
}
</style>

