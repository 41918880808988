// Import translations for English locale
import dashboardSidebarEn from '@/locale/components/dashboard-sidebar-en.json'
import eventBuilderEn from '@/locale/components/event-sidebar-en.json'
import eventTopNavEn from '@/locale/components/event-top-nav-en.json'
import eventBottomNavEn from '@/locale/components/event-bottom-nav-en.json'
import dropdownMenuEn from '@/locale/components/dropmenu-en.json'
import pageGeneralEn from '@/locale/pages/general-en.json'

// Import translation for Arabic locale
import dashboardSidebarAr from '@/locale/components/dashboard-sidebar-ar.json'
import eventBuilderAr from '@/locale/components/event-sidebar-ar.json'
import eventTopNavAr from '@/locale/components/event-top-nav-ar.json'
import eventBottomNavAr from '@/locale/components/event-bottom-nav-ar.json'
import dropdownMenuAr from '@/locale/components/dropmenu-ar.json'
import pageGeneralAr from '@/locale/pages/general-ar.json'

export const messages = {
  en: {
    dashboardSidebar: dashboardSidebarEn,
    eventBuilder: eventBuilderEn,
    eventTopNav: eventTopNavEn,
    eventBottomNav: eventBottomNavEn,
    dropdownMenu: dropdownMenuEn,
    pageGeneral: pageGeneralEn
  },
  ar: {
    dashboardSidebar: dashboardSidebarAr,
    eventBuilder: eventBuilderAr,
    eventTopNav: eventTopNavAr,
    eventBottomNav: eventBottomNavAr,
    dropdownMenu: dropdownMenuAr,
    pageGeneral: pageGeneralAr
  }
}
