<script setup>
// Required Packages
import { computed, onMounted, ref, watch } from 'vue'
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'
// Store
import store from '@/store'
// Base Components
import BaseButton from '@/components/Base/Button/BaseButton'
import EventPageBase from './EventPageBase'
// Custom Components
import TimelineForm from '@/components/Timeline/TimelineForm'
// Styles
import 'balloon-css'
// Utils
import {
  processStatuses,
  bitwiseOperators,
  eventStatuses
} from '@/utils'
import day from 'dayjs'
import utc from 'dayjs/plugin/utc'

day.extend(utc)

let counterValue = ref(0)

const router = new useRouter()
const route = new useRoute()

const event = computed(() => {
  return store.getters.eventBuilderEvent
})

const timelineData = computed(() => {
  return store.getters.timelineData
})

const timelineStatus = computed(() => {
  return store.getters.timelineStatus
})

const isTimelineSaving = computed(() => {
  return timelineStatus.value === processStatuses.SAVING
})

const timelineErrors = computed(() => {
  return store.getters.timelineError
})

const isTimelineLoaded = computed(() => {
  return timelineStatus.value === processStatuses.LOADED
})

const eventLoadingStatus = computed(() => {
  return store.getters.ebEventStatus
})

const canUpdate = computed(() => {
  return store.getters.canTimelineUpdate
})

const checkValidation = (formData, timelineCount) => {
  let timelineName = ''
  Object.keys(formData).filter(item => {
    if(item === 'name') {
      timelineName = formData[item]
    }
    if((!formData[item] || formData[item] === null) && timelineName === 'Project Submission Period' && item !== 'startAt') {
      const formItemPayload = {
        'id': formData.id,
        'key': item,
        'errMsg':
          item === "endAt" ? "Please select end date" : 'Please enter a '+ item}
      store.getters.timelineError[formItemPayload.id] = {...store.getters.timelineError[formItemPayload.id], [formItemPayload.key] : formItemPayload.errMsg}
    }
    else if (timelineName === 'Project Submission Period' && item === 'startAt' && formData['startAt'] !== null && formData['endAt'] !== null && new Date(formData['startAt']) > new Date(formData['endAt'])) {
      const formItemPayload = {
        'id': formData.id,
        'key': 'endAt',
        'errMsg': "It looks like your end date is in the past. Please update if you would like more time for Submissions."
      }
      store.getters.timelineError[formItemPayload.id] = {...store.getters.timelineError[formItemPayload.id], [formItemPayload.key] : formItemPayload.errMsg}
    }
    if((!formData[item] || formData[item] === null) && timelineName === 'Project Showcase') {
      const formItemPayload = {
        'id': formData.id,
        'key': item,
        'errMsg': item === 'startAt' ? "Please select start date" : item === "endAt" ? "Please select end date" : 'Please enter a '+ item}
      store.getters.timelineError[formItemPayload.id] = {...store.getters.timelineError[formItemPayload.id], [formItemPayload.key] : formItemPayload.errMsg}
    }
  })
  if (Object.keys(store.getters.timelineError).length === 0) {
    store.dispatch('setTimelineData', formData).then(() => {
      counterValue.value++
      if(timelineCount === counterValue.value) {
        store.dispatch('setTimelinePreview').then(() =>{
          counterValue.value = 0
        })
        store.dispatch('updateEventConfigs', { eventId: store.getters.ebEventId, configKey: "event_config_completion", configValue: "16", isConfigValueBitMask: true, bitwiseOperation: bitwiseOperators.COMBINE }).then(() => {
          store.dispatch('getEventConfigs', { eventId: store.getters.ebEventId})
          store.dispatch('triggerToast',{ message: 'Your timeline has been successfully saved.', type: 'success'}).then(() => {
            if (route.params.slug !== event.value.slug) {
              router.push({ name:'Timeline', params: { slug: event.value.slug } })
            }
            // update flag isEventDataChanged
            store.dispatch('setEventDataChanged', false)
          })
        }).catch((err) => {
          store.dispatch('triggerToast', { message: 'Your timeline has not been successfully saved.', type: 'error'})
          console.error(err)
        })
      }
    })
  }
}

const save = () => {
  store.dispatch('timelineErrorReset')
  const timelineCount = timelineData.value.length
  timelineData.value.filter(item => {
    const payload = {
      id: item.id,
      name: item.name,
      endAt: item.endAt,
      eventId: store.getters.eventBuilderEvent.id
    }
    if (item.name === 'Project Showcase') {
      payload.startAt = item.startAt
    }
    checkValidation(payload, timelineCount)
  })
}

const next = () => {
  router.push({ name: 'Resources', params: { slug: event.value.slug }})
  window.scrollTo(0, 0)
}

const prev = () => {
  router.push({ name: 'ProjectTemplates', params: { slug: event.value.slug }})
  window.scrollTo(0, 0)
}

const canUpdateTimeline = () => {
  const now = day.utc()
  if(event.value.eventSettings?.status !== eventStatuses?.LIVE) {
    store.dispatch('setCanTimelineUpdate', true)
  } else {
    if (timelineData.value[1]?.name === 'Project Showcase' && (timelineData.value[1]?.startAt === null || now <= day.utc(timelineData.value[1]?.startAt))) {
      store.dispatch('setCanTimelineUpdate', true)
    } else {
      store.dispatch('setCanTimelineUpdate', false)
    }
  }
}

const upgradePlan = () => {
  if (upgradeButtonLoading.value || upgradeButtonDisabled.value) {
    return
  }

  if (store.getters.hasRemainingPaidEvents(subscriptionDetails.value?.plan?.name)) {
    store.dispatch('ToggleModalUpgradeEvent', { eventId: store.getters.ebEventId })
  } else {
    store.dispatch('ToggleModalUpgradePayment', {
      eventId: store.getters.ebEventId,
      currentPlan: subscriptionDetails.value?.plan?.name
    })
  }
}

const subscriptionDetails = computed(() => {
  return store.getters.getEventSubscriptionDetails
})

const upgradeButtonLoading = computed(() => {
  return store.getters.ebEventStatus !== processStatuses.LOADED
    || store.getters.getEventSubscriptionDetailsStatus !== processStatuses.LOADED
    || store.getters.subscriptionPlansStatus !== processStatuses.LOADED
    || store.getters.getOrganizationStatus !== processStatuses.LOADED
})

const upgradeButtonDisabled = computed(() => {
  return subscriptionDetails.value?.plan?.name === 'premium'
})

const isEventDataChanged = computed(() => {
  return store.getters.isEventDataChanged
})

onBeforeRouteLeave((to, from, next) => {
  if(isEventDataChanged.value){
    const payload = {
      confirmAction: () => {
        store.dispatch('ResetModal')
        next()
      },
      cancelAction: () => {
        store.dispatch('ResetModal')
      }
    }
    store.dispatch('setEventDataChanged', false)
    store.dispatch('ToggleModalUnsavedAlert', payload)
  } else {
    next()
  }
})

watch(timelineData, (oldData, newData) => {
  if(oldData !== newData) {
    canUpdateTimeline()
  }
  const submission = newData.find(item => item.name === 'Project Submission Period')
  if (submission && submission?.endAt !== null && submission?.endAt !== undefined && (submission?.startAt ? new Date(submission?.startAt) <= new Date(): true) && new Date(submission?.endAt) <= new Date()) {
    store.dispatch('setSubmissionEndError', { id: submission?.id })
  }
}, {deep: true})

watch(eventLoadingStatus, async(status, oldStatus) => {
  if (oldStatus === processStatuses.LOADING && status === processStatuses.LOADED) {
    await store.dispatch('getTimelineData', store.getters.eventBuilderEvent.id)
  }
})

onMounted(() => {
  store.dispatch('timelineErrorReset')
  if (store.getters.isEBEventTemplateLoaded) {
    store.dispatch('getTimelineData', store.getters.eventBuilderEvent.id)
  }
  canUpdateTimeline()
})
</script>

<template>
  <event-page-base header-class="md:max-w-[50rem]">
    <template #title>
      Key Dates
    </template>
    <template #description>
      These are the important dates for gathering and showcasing projects for your event.
    </template>
    <template #content>
      <div v-if="isTimelineLoaded" class="timeline-content">
        <div class="timeline-content-inner">
          <span v-for="(timeData, index) in timelineData"
                :key="timeData.id">
            <timeline-form :id="timeData.id.toString()"
                           v-model="timelineData[index]"
                           :position="index + 1"
                           :can-update="canUpdate"
                           :timeline-error-messages="timelineErrors[timeData.id]"/>
          </span>
          <p class="timeline-content-inner-note">*Note: We have got you covered! ProjectBoard will send out reminder emails based on your timeline.</p>
        </div>
        <div v-if="!canUpdate" class="timeline-content-inner-note-update">
          <i class="fa-regular fa-triangle-exclamation timeline-content-inner-note-update-icon"></i>
          <p class="timeline-content-inner-note-update-text">Your Project Showcase is happening now. No more changes can be made at this time.<br>
            <span v-if="!upgradeButtonDisabled">If you’d like to extend your project showcase, </span>
            <a v-if="upgradeButtonLoading || !upgradeButtonDisabled"
                class="timeline-content-inner-note-update-text-link"
                href="#"
                @click.prevent="upgradePlan">Upgrade Now</a></p>
        </div>
      </div>
    </template>
    <timeline-form id="1" :position="1" />
    <template #back-button>
      <base-button
          id="bottom-nav-prev"
          name="bottom-nav-prev"
          class-name="bottom-nav-button"
          btn-type="text"
          :text="$t('eventBottomNav.back')"
          :functions="prev">
        <template #contents>
          <span class="bottom-nav-button-text">
            <span class="bottom-nav-circle">
              <i class="fa-solid fa-chevron-left" />
            </span>
            {{ $t('eventBottomNav.back') }}
          </span>
        </template>
      </base-button>
    </template>
    <template #next-button>
      <base-button
          id="bottom-nav-next"
          name="bottom-nav-next"
          class-name="bottom-nav-button"
          btn-type="text"
          :functions="next"
          :text="$t('eventBottomNav.next')">
        <template #contents>
          <span class="bottom-nav-button-text">
            <span class="bottom-nav-circle">
              <i class="fa-solid fa-chevron-right" />
            </span>
            {{$t('eventBottomNav.next')}}
          </span>
        </template>
      </base-button>
    </template>
    <template #save-button>
      <base-button id="bottom-nav-confirm"
                   name="bottom-nav-confirm"
                   :disabled="isTimelineSaving"
                   :is-loading="isTimelineSaving"
                   :functions="save"
                   class-name="timeline-button"
                   :text="$t('eventBottomNav.updateBtn')"/>
    </template>
  </event-page-base>
</template>

<style lang="scss" scoped>
.timeline {
  &-content {
    @apply pb-28;
    &-inner {
      @apply border border-blacks-100 w-full md:max-w-[45rem] bg-background-100 rounded-2xl mt-10 md:px-10 px-5 py-5;
      &-note {
        @apply text-p-xs text-blacks-500 mt-5;
        &-update {
          @apply flex items-center mt-4;
          &-icon {
            @apply text-other-red text-h2 mr-2;
          }
          &-text {
            @apply text-other-red text-p-md;
            &-link {
              @apply underline font-bold;
              &.disabled {
                @apply pointer-events-none;
              }
            }
          }
        }
      }
    }
  }
  &-button {
    @apply capitalize px-6 py-2 mt-1;
  }
}
</style>
